import Vue from 'vue'
import VueRouter from 'vue-router'

import VHome from './views/VHome.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: { name: 'home' }
  }
]

routes.unshift({
  path: '/',
  name: 'home',
  component: VHome
},
{
  path: '/antrag',
  name: 'request',
  redirect: { name: 'request-step-0' },
  component: () => import('./views/VRequest.vue'),
  children: [
    {
      path: 'bericht',
      name: 'request-step-0',
      component: () => import('./views/VRequestStep0.vue')
    },
    {
      path: 'zusammenfassung',
      name: 'request-step-1',
      meta: {
        lastStep: true
      },
      component: () => import('./views/VRequestStep1.vue')
    },
    {
      path: '*',
      redirect: { name: 'request' }
    }
  ]
},
{
  path: '/vielen-dank',
  name: 'thank-you',
  component: () => import('./views/VThankYou.vue'),
  beforeEnter (to, from, next) {
    if (from.meta.lastStep) {
      next()
    } else {
      next({ name: 'home' })
    }
  }
})

function scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }

  return { x: 0, y: 0 }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes,
  scrollBehavior
})

router.afterEach(async () => {
  await Vue.nextTick()
  const h1 = document.querySelector('h1')

  if (h1) {
    h1.focus()
  }
})

export default router
